<!--  -->
<template>
    <div align="center" class='historypage'>
        <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
            <div style="margin:45px 57px">
                <el-row class="title">
                    <el-col :span="6">
                        <div class="blank"></div>
                    </el-col>
                    <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;"> 心电检测报告
                        </span></el-col>
                    <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                            src="../../../../../public/img/logo_xingou.png" /></el-col>
                </el-row>
                <!-- 绘制双横线 -->
                <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
                <el-row class="info">
                    <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            姓名：{{ realName }}</span></el-col>
                    <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            性别：{{ gender }}</span></el-col>
                    <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            年龄：{{ age }}</span></el-col>
                    <el-col :span="9"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            时间：{{ createTime }} </span></el-col>
                </el-row>
                <!-- 绘制单横线 -->
                <hr style="border-top: 2px solid gray;" />
                <el-row class="info" style="height:30px">
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            PR间期：{{ conclusion.pr }}ms</span></el-col>
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            QT间期：{{ conclusion.qt }}ms</span></el-col>
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            QTc间期：{{ conclusion.qtc }}ms</span></el-col>
                </el-row>
                <el-row class="info" style="height:30px">
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                            心率：{{ conclusion.xinlv }}bpm</span></el-col>
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> P时限：
                            {{ conclusion.p }}ms</span></el-col>
                    <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;"> QRS时限：
                            {{ conclusion.qrs }}ms</span></el-col>
                </el-row>

                <div class="wapper">
                    <!-- 网格  -->
                    <svg id="svg" class="svg"></svg>
                </div>
                <!-- 疾病疑似 -->
                <div class="disease_content">
                    <el-table :data="tableData" :header-cell-style="{ padding: '0px' }"
                        :header-row-style="{ height: '30px' }" style="width: 100% height: 60px"
                        :row-style="{ height: '24px' }" :cell-style="{ padding: '0px' }" border>
                        <el-table-column type="index" label="NO." align="center" width="auto">
                        </el-table-column>
                        <el-table-column prop="items" label="检测项目" width="auto" align="center">
                        </el-table-column>
                        <el-table-column prop="suspectedDegrees" label="疑似度" width="auto" align="center">
                        </el-table-column>
                        <el-table-column prop="conclusion" label="结论" width="auto" align="center">
                        </el-table-column>
                    </el-table>

                    <div style="text-align:right;font-size:14px">*提示:报告仅供参考，请结合临床情况</div>
                    <br>
                    <div style="text-align:right;font-size:14px">*模型信息: {{ conclusion.method }}</div>
                </div>
            </div>
        </div>
        <!-- <canvas id="ecg" width="793.7px" height="1122.52px" style="border:black 1px solid">您的浏览器不支持canvas</canvas><br> -->
        <el-button type="primary" @click="showMarkPoint()">查看标记点</el-button>
        <el-button type="primary" plain @click="getPdf('#DomPdf')">下载心电报告</el-button>
        <!-- <el-button type="primary" plain onclick="">AI诊断</el-button> -->
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    // import引入的组件需要注入到对象中才能使用

    // props传递属性（从checkContent中来）
    props: {
        userId: Number,  // 【其他平台】读取的属性
        recordId: String, // 【其他平台】读取的属性
        createTimeId: String, // 【其他平台】读取的属性
        durationId: Number, //【其他平台】读取的属性
    },
    components: {},
    data() {
        //这里存放数据
        return {
            uid: this.userId,  // 从props中赋值
            sid: this.recordId, // 从props中赋值
            createTime: this.createTimeId,  // 从props中赋值
            duration: this.durationId, // 从props中赋值
            htmlTitle: 'PDF_ECG',
            tableData: [{ items: "窦性心动过速", suspectedDegrees: "", conclusion: "" },
            { items: "窦性心动过缓", suspectedDegrees: "", conclusion: "" },
            { items: "窦性心律不齐", suspectedDegrees: "", conclusion: "" },
            // { items: "窦性停搏", suspectedDegrees: "", conclusion: "" },
            { items: "房性早搏", suspectedDegrees: "", conclusion: "" },
            { items: "交界性早搏", suspectedDegrees: "", conclusion: "" },
            { items: "室性早搏", suspectedDegrees: "", conclusion: "" },
            { items: "室上性心动过速", suspectedDegrees: "", conclusion: "" },
            { items: "室性心动过速", suspectedDegrees: "", conclusion: "" },
            { items: "交界性逸搏", suspectedDegrees: "", conclusion: "" },
            { items: "室性逸搏", suspectedDegrees: "", conclusion: "" },
            { items: "房颤", suspectedDegrees: "", conclusion: "" },
            { items: "心梗相关疾病", suspectedDegrees: "", conclusion: "" }],
            ecgData: [],
            conclusion: {
                pr: 12,
                date: '',
                qt: '',
                qtc: '',
                p: '',
                qrs: '',
                xinlv: '',
                method: '规则模型',
            },
            gender: '',
            age: '',
            realName: '',
            endTime: '',
            MarkPointFlag: false,
        };
    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: {
        get() { return this.$store.state.module.crossPlatform },
        set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        },
    },
    //监控data中的数据变化
    watch: {
        //数据自动刷新，必然需要一个监听机制告诉Echarts重新设置数据


    },
    //方法集合
    methods: {
        // 计算结束时间
        EndTime() {
            var startTime = +new Date(this.createTime.substring(0, 4),
                this.createTime.substring(5, 7) - 1,
                this.createTime.substring(8, 10),
                this.createTime.substring(11, 13),
                this.createTime.substring(14, 16),
                this.createTime.substring(17, 19));
            var endTime = new Date(startTime + this.duration * 1000)
            let y = endTime.getFullYear();
            let MM = endTime.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = endTime.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = endTime.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = endTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = endTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
            this.endTime = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒
        },


        drawECG() {
            // this. init_Canvas();
            var svg_grid = this.$Snap('#svg');
            // 图层零点（1,1）
            // 注意图层顺序，先画小的，再画大的
            // 绘制小方格
            for (let j = 1; j < 180; j++) {
                svg_grid.line(0, 3.75 * j + 1, 675, 3.75 * j + 1).attr({
                    stroke: "#e03433",
                    strokeWidth: 0.3,
                });
                svg_grid.line(3.75 * j + 1, 0, 3.75 * j + 1, 675).attr({
                    stroke: "#e03433",
                    strokeWidth: 0.3,
                });
            }
            // 绘制大方格
            for (let i = 0; i < 37; i++) {
                svg_grid.line(0, 18.75 * i + 1, 675, 18.75 * i + 1).attr({
                    stroke: "#e03433",
                    strokeWidth: 1.2,
                });
                svg_grid.line(18.75 * i + 1, 0, 18.75 * i + 1, 675).attr({
                    stroke: "#e03433",
                    strokeWidth: 1.2,
                });
            }
            var t1 = svg_grid.paper.line(20, 21, 41, 21).attr({
                stroke: "#000",
                strokeWidth: 5
            });
            // 注释
            var t2 = svg_grid.paper.text(15, 16, "5mm");
            var t3 = svg_grid.paper.text(100, 25, "时间:" + this.conclusion.date);
            var t5 = svg_grid.paper.text(400, 25, "增益：10mm/mV");
            var t6 = svg_grid.paper.text(550, 25, "走速：25mm/s");
            var t7 = svg_grid.paper.text(480, 485, "时间" + this.endTime);
            // 标志
            for (let y = 0; y <= 400; y += 18.75 * 8) {
                var p1 = svg_grid.paper
                    .polyline(
                        2,
                        113.5 + y,
                        6,
                        113.5 + y,
                        6,
                        76 + y,
                        15,
                        76 + y,
                        15,
                        113.5 + y,
                        19,
                        113.5 + y
                    )
                    .attr({
                        fill: "none",
                        stroke: "black",
                        strokeWidth: 2,
                    });
            }
            // 绘制心电图
            // 心电图坐标零点x0 = 34px，y0 = 68+17*i，一页画10秒，一秒5大格，一大格5小格，一小格3.75px
            // 8导联采样频率500Hz，一秒500个点，一大格100个点，一小格20个点，点与点之间相邻0.1875px
            var ecgData1 = this.ecgData.slice(0, 3430);
            var ecgData2 = this.ecgData.slice(3430, 3430 * 2);
            var ecgData3 = this.ecgData.slice(3430 * 2, this.ecgData.length + 1);
            var yValue1 = ecgData1.map(function (index) {
                return 113.5 - (index * 0.0360608 / 320000) * 18.75 * 2;          //* 0.0360608 / 320000表示mV信号的转换，* 18.75*8表示计算的增益
            });
            var yValue2 = ecgData2.map(function (index) {
                return 113.5 + 18.75 * 8 - (index * 0.0360608 / 320000) * 18.75 * 2;
            });
            var yValue3 = ecgData3.map(function (index) {
                return 113.5 + 18.75 * 16 - (index * 0.0360608 / 320000) * 18.75 * 2;
            });
            var len1 = yValue1.length;
            var len2 = yValue2.length;
            var len3 = yValue3.length;
            for (let k = 0; k < len1; k++) {
                yValue1.splice(2 * k, 0, 19 + 0.1875 * k);
            }
            for (let k = 0; k < len2; k++) {
                yValue2.splice(2 * k, 0, 19 + 0.1875 * k);
            }
            for (let k = 0; k < len3; k++) {
                yValue3.splice(2 * k, 0, 19 + 0.1875 * k);
            }
            var ecg1 = svg_grid.paper.polyline(yValue1).attr({
                fill: "none",
                stroke: "black",
                strokeWidth: 1,
            });
            var ecg2 = svg_grid.paper.polyline(yValue2).attr({
                fill: "none",
                stroke: "black",
                strokeWidth: 1,
            });
            var ecg3 = svg_grid.paper.polyline(yValue3).attr({
                fill: "none",
                stroke: "black",
                strokeWidth: 1,
            });
        },
        drawMarkPoint() {

            var svg_grid = this.$Snap('#svg')

            // 标记点绘制 q r s s2 p t
            var colorarray = ["Yellow", "LimeGreen", "DeepSkyBlue", "SandyBrown", "DeepPink", "BlueViolet"] // 色谱
            this.MK = []
            for (let i = 0; i < 6; i++) {
                var MarkPoint = "";
                if (i == 0) {
                    MarkPoint = this.Qlist
                }
                else if (i == 1) {
                    MarkPoint = this.Rlist
                }
                else if (i == 2) {
                    MarkPoint = this.Slist
                }
                else if (i == 3) {
                    MarkPoint = this.S2list
                }
                else if (i == 4) {
                    MarkPoint = this.Plist
                }
                else {
                    MarkPoint = this.Tlist
                }
                var R = MarkPoint.map(function (index) {
                    return 19 + 0.1875 * index;
                });

                for (let k = 0; k < MarkPoint.length; k++) {
                    if (MarkPoint[k] == 0)        // 去掉标记点为0的点
                    {
                        continue
                    }
                    else if (MarkPoint[k] < 3430) {
                        this.MK.push(svg_grid.paper.circle(R[k], 113.5 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
                            fill: "none",
                            stroke: colorarray[i],
                            strokeWidth: 1.5,
                        }));

                    }
                    else if (MarkPoint[k] >= 3430 && MarkPoint[k] < 6860) {
                        this.MK.push(svg_grid.paper.circle(R[k] - 3430 * 0.1875, 113.5 + 18.75 * 8 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
                            fill: "none",
                            stroke: colorarray[i],
                            strokeWidth: 1.5,
                        }));
                    }
                    else {
                        this.MK.push(svg_grid.paper.circle(R[k] - 3430 * 0.1875 * 2, 113.5 + 18.75 * 16 - (this.ecgData[MarkPoint[k]] * 0.0360608 / 320000) * 18.75 * 2, 3).attr({
                            fill: "none",
                            stroke: colorarray[i],
                            strokeWidth: 1.5,
                        }));
                    }
                }

            }
            console.log(this.MK[0])
        },
        getData() {
            this.$http.adornHeader(this.header),
                // 根据每条心电记录的sid获取该条的数据和结论
                this.$http({
                    url: this.$http.adornUrl('/personal/staticecg/getEcgData'),
                    method: 'get',
                    params: this.$http.adornParams({ sid: this.sid })
                }).then(({ data }) => {
                    if (data && data.code === 0) {

                        this.ecgData = data.ecgData
                        // console.log("看看数据")
                        // console.log(this.ecgData)
                        this.ecgData = this.ecgData.split(",")
                        this.drawECG();
                        this.MarkPointFlag = true   //【查看标记点的开关】
                        this.showMarkPoint();
                    } else {
                        // this.getCaptcha()
                        this.$message.error(data.msg)
                    }
                })
            this.$http({
                url: this.$http.adornUrl('/personal/staticecg/getConclusion'),
                method: 'get',
                params: this.$http.adornParams({ sid: this.sid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    console.log(data)
                    this.conclusion_random = data.conclusionData
                    this.conclusion = JSON.parse(this.conclusion_random)   //JSON格式转换
                    // this.conclusion.rlist = this.conclusion.rlist.shift()
                    console.log(this.conclusion)
                    if (this.conclusion.method != 0) {
                        this.conclusion.method = '深度学习'
                    }
                    this.Rlist = this.conclusion.rlist.split(",")
                    this.Qlist = this.conclusion.qlist.split(",")
                    this.Slist = this.conclusion.slist.split(",")
                    this.S2list = this.conclusion.s2list.split(",")
                    this.Plist = this.conclusion.plist.split(",")
                    this.Tlist = this.conclusion.tlist.split(",")
                    var Disease = [this.conclusion.xdgs, this.conclusion.xdgh, this.conclusion.xlbq, this.conclusion.fxzb,
                    this.conclusion.jjxzb, this.conclusion.sxzb, this.conclusion.ssxxdgs, this.conclusion.sxxdgs,
                    this.conclusion.fxyb, this.conclusion.sxyb, this.conclusion.fc, this.conclusion.qtyc]
                    // 疑似度
                    for (let k = 0; k < 11; k++) {
                        this.tableData[k].suspectedDegrees = Disease[k] + "%"
                    }
                    this.tableData[11].suspectedDegrees = '-' //心梗没有疑似度 
                    // 结论
                    for (let i = 0; i < 11; i++) //除了心梗相关疾病的结论
                    {
                        this.tableData[i].conclusion = '正常'
                        if (Disease[i] > 50) {
                            this.tableData[i].conclusion = '偏高'
                        }
                    }

                    this.tableData[11].conclusion = this.conclusion.con13 //心梗相关疾病的结论
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    this.realName = data.realName
                    this.age = data.age
                    this.gender = data.gender
                    var genderMap = { 0: "女", 1: "男" }
                    this.gender = genderMap[this.gender]
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })

        },
        showMarkPoint() {
            this.MarkPointFlag = !this.MarkPointFlag
            // console.log(this.MarkPointFlag)
            if (this.MarkPointFlag == true) {
                this.drawMarkPoint()
            }
            else {
                if (this.MK != undefined) {
                    for (let i = 0; i < this.MK.length; i++)
                        this.MK[i].remove()
                }
            }
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.$route.query.crossPlatform != null && this.$route.query.crossPlatform != undefined) {
            // 小程序端请求
            this.crossPlatformStatus = true
        }
        if (this.$store.state.module.crossPlatform) {
            console.log('yes')
            this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
        }
        // this.sid='500'
        console.log(this.$store.state.women_baby.uid);
        if (this.$route.query.staticRecordId != null && this.$route.query.staticRecordId != undefined) {
            this.uid = this.$route.query.uid
            this.sid = this.$route.query.staticRecordId
            this.createTime = this.$route.query.staticRecordCreatetime
            this.duration = this.$route.query.staticRecordDuration
        }

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

        this.getData();
        this.EndTime();


        // this.drawECG();


    },
    beforeCreate() {

    }, //生命周期 - 创建之前
    beforeMount() {

    }, //生命周期 - 挂载之前
    beforeUpdate() {

    }, //生命周期 - 更新之前
    updated() {

    }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}

.wapper {
    position: relative;
    height: 525px;
}

.svg {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    height: 527px;
}
</style>
